import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.tableData,
      breakpoint: "1267px",
      "data-key": "account",
      onRowClick: _cache[0] || (_cache[0] = ($event) => _ctx.$emit('account-row-click', $event.data))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "account",
          header: "Account",
          headerClass: "font-bold",
          bodyClass: "font-normal text-center",
          sortable: ""
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", {
              class: _normalizeClass(["dot", {'green-dot': !data.inactive, 'gray-dot': data.inactive}])
            }, null, 2),
            _createElementVNode("span", null, _toDisplayString(data.account), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "accountDescription",
          header: "Account Description",
          headerClass: "font-bold",
          bodyClass: "font-normal font-semibold text-center",
          sortable: ""
        }),
        _createVNode(_component_Column, {
          field: "debit",
          header: "Debit",
          headerClass: "font-bold",
          bodyClass: "font-normal font-semibold text-right",
          sortable: ""
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.formatedAmount(data.debit)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "credit",
          header: "Credit",
          headerClass: "font-bold",
          bodyClass: "font-normal font-semibold text-right",
          sortable: ""
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.formatedAmount(data.credit)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}